import isObject from 'lodash/isObject';
var bindUrlImage = function bindUrlImage(urlImage, isCatalog, isFeaturedSection) {
  var _urlImage$imageDetail, _urlImage$imageCatalo, _urlImage$imageHome;
  return isObject(urlImage) ? isFeaturedSection ? (_urlImage$imageDetail = urlImage.imageDetail1) === null || _urlImage$imageDetail === void 0 ? void 0 : _urlImage$imageDetail.url : isCatalog ? (_urlImage$imageCatalo = urlImage.imageCatalog) === null || _urlImage$imageCatalo === void 0 ? void 0 : _urlImage$imageCatalo.url : (_urlImage$imageHome = urlImage.imageHome) === null || _urlImage$imageHome === void 0 ? void 0 : _urlImage$imageHome.url : '';
};
export default (function (product, isCatalog, isFeaturedSection) {
  var _product$productImage, _product$productImage2;
  return isObject(product) ? {
    altText: product.productName,
    prefijo: product.prefix,
    copago: product.copayPrice,
    puntosPlata1: product.points1,
    puntosPlata2: product.points2,
    puntosPlata3: product.points3,
    marca: product.brand,
    variante: product.variant,
    id: product.id,
    urlImagen: bindUrlImage(product.productImage, isCatalog, isFeaturedSection),
    nombre: product.productName,
    puntos: product.point ? product.point : product.points,
    tagNuevo: product.newProductTag,
    tagOferta: product.offerProductTag,
    stock: 'null',
    icono: null,
    tienda: product.store,
    cantidadProductos: null,
    sku: product.productStock,
    descripcionLarga: product.description,
    condicionesDeCanje: product.exchangeConditions,
    imagenes: [(_product$productImage = product.productImage) === null || _product$productImage === void 0 ? void 0 : (_product$productImage2 = _product$productImage.imageDetail1) === null || _product$productImage2 === void 0 ? void 0 : _product$productImage2.url]
  } : {};
});