import isArray from 'lodash/isArray';
import shuffle from 'lodash/shuffle';
import productOnlineBinder from './ProductOnlineBinder';
import bannerBinder from './BannerBinder';
import carouselBinder from './CarouselBinder';
import bannerVideoBinder from './BannerVideoBinder';
import productStoreBinder from '@cencosud/puntos-core/src/features/binder/ProductStoreBinder';
import popupBinder from '@cencosud/puntos-core/src/features/binder/PopupBinder';
import isObject from 'lodash/isObject';
import { isUndefined } from 'lodash';
import { bindCategory } from '@cencosud/puntos-core/src/features/binder/CategoryBinder';
var homesListBinder = function homesListBinder(arrayToFilter, bindMethod) {
  var filteredArray = arrayToFilter.filter(function (item) {
    return item !== null;
  });
  var newArray = filteredArray.map(function (element) {
    return bindMethod(element);
  });
  return newArray;
};
var carouselListBinder = function carouselListBinder(arrayCarousel) {
  return homesListBinder(arrayCarousel, carouselBinder);
};
var listBannerBinder = function listBannerBinder(arrayBanner) {
  return homesListBinder(arrayBanner, bannerBinder);
};
var listBannerSquareBinder = function listBannerSquareBinder(arrayBanner) {
  if (arrayBanner.length % 2 !== 0) arrayBanner.pop();
  return homesListBinder(arrayBanner, bannerBinder);
};
var listProductOnlineBinder = function listProductOnlineBinder(featuredProducts) {
  return homesListBinder(featuredProducts, productOnlineBinder);
};
var listProductStoreBinder = function listProductStoreBinder(featuredProducts) {
  return homesListBinder(featuredProducts, productStoreBinder);
};
var listCategoryBinder = function listCategoryBinder(featuredCategory) {
  var auxFeaturedCategory = featuredCategory < 9 ? featuredCategory : featuredCategory.slice(0, 8);
  return homesListBinder(auxFeaturedCategory, bindCategory);
};
export default (function (home) {
  if (!isObject(home)) return {};
  var bannersUDN = [home.bannerUdn1 ? bannerBinder(home.bannerUdn1) : null, home.bannerUdn2 ? bannerBinder(home.bannerUdn2) : null, home.bannerUdn3 ? bannerBinder(home.bannerUdn3) : null, home.bannerUdn4 ? bannerBinder(home.bannerUdn4) : null];
  var shuffledBanners = shuffle(bannersUDN);
  return {
    principalBanner: isArray(home.principalBanner) ? carouselListBinder(home.principalBanner) : [],
    randomPrincipalBanner: home.randomPrincipalBanner,
    featuredCategories: listCategoryBinder(home.featuredCategories || []),
    carrusel: isArray(home.carousel1) ? carouselListBinder(home.carousel1) : [],
    carrusel2: isArray(home.carousel2) ? carouselListBinder(home.carousel2) : [],
    carrusel3: isArray(home.carousel3) ? carouselListBinder(home.carousel3) : [],
    productosOnline: isArray(home.featuredProductsOnline) ? listProductOnlineBinder(home.featuredProductsOnline.filter(function (item) {
      return item !== null;
    })) : [],
    productosTienda: isArray(home.featuredProductsStore) ? listProductStoreBinder(home.featuredProductsStore.filter(function (item) {
      return item !== null;
    })) : [],
    bannerCanje: home.featuredBannerOnline ? bannerBinder(home.featuredBannerOnline) : {},
    comoAcumular: home.howAccumulate ? bannerVideoBinder(home.howAccumulate) : null,
    comoCanjear: home.howExchange ? bannerVideoBinder(home.howExchange) : null,
    carruselClubes: isArray(home.clubCarousel) ? carouselListBinder(home.clubCarousel.filter(function (item) {
      return item !== null;
    })) : [],
    bannersUDN: shuffledBanners,
    campagna: popupBinder(home.popup),
    bannerEstatico: isUndefined(home.staticBanner) ? null : bannerBinder(home.staticBanner),
    bannerCatalogo: bannerBinder(home.onlineBannerCatalog),
    secondaryBannerThinCover1: isArray(home.secondaryBannerThinCover1) ? listBannerBinder(home.secondaryBannerThinCover1) : [],
    secondaryBannerSquares1: isArray(home.secondaryBannerSquares1) ? listBannerSquareBinder(home.secondaryBannerSquares1) : [],
    secondaryBannerThinCover2: isArray(home.secondaryBannerThinCover2) ? listBannerBinder(home.secondaryBannerThinCover2) : [],
    secondaryBannerSquares2: isArray(home.secondaryBannerSquares2) ? listBannerSquareBinder(home.secondaryBannerSquares2) : [],
    featuredHome1: home.featuredHome1,
    featuredHome2: home.featuredHome2,
    featuredHome3: home.featuredHome3,
    discoverPointsImg: home.discoverPointsImg,
    discoverPointsTextList: home.discoverPointsTextList,
    discoverPointsButtonList: home.discoverPointsButtonList,
    waysToExchange: home.waysToExchange,
    waysToAccumulate: home.waysToAccumulate,
    faq: home.faq
  };
});