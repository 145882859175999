import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var namespace = '[usuario]';
export var GET_DETALLE = "".concat(namespace, " get detalle");
export var GET_DETALLE_SUCCESS = "".concat(GET_DETALLE, ": success");
export var GET_DETALLE_FAIL = "".concat(GET_DETALLE, ": fail");
export var SET_DETALLE = "".concat(namespace, " set detalle");
export var APPEND_DETALLE = "".concat(namespace, " append detalle");
export var ACTUALIZAR_DETALLE = "".concat(namespace, " actualizar detalle");
export var ACTUALIZAR_DETALLE_SUCCESS = "".concat(ACTUALIZAR_DETALLE, ": success");
export var ACTUALIZAR_DETALLE_FAIL = "".concat(ACTUALIZAR_DETALLE, ": fail");
export var ACTUALIZAR_TELEFONO = "".concat(namespace, " actualizar telefono");
export var ACTUALIZAR_TELEFONO_SUCCESS = "".concat(ACTUALIZAR_TELEFONO, ": success");
export var ACTUALIZAR_TELEFONO_FAIL = "".concat(ACTUALIZAR_TELEFONO, ": fail");
export var ACTUALIZAR_CORREO = "".concat(namespace, " actualizar email");
export var ACTUALIZAR_CORREO_SUCCESS = "".concat(ACTUALIZAR_CORREO, ": success");
export var ACTUALIZAR_CORREO_FAIL = "".concat(ACTUALIZAR_CORREO, ": fail");
export var GENERAR_CODIGO_USUARIO = "".concat(namespace, " generar codigo");
export var GENERAR_CODIGO_USUARIO_SUCCESS = "".concat(GENERAR_CODIGO_USUARIO, ":success");
export var GENERAR_CODIGO_USUARIO_FAILED = "".concat(GENERAR_CODIGO_USUARIO, " : fail");
export var ACTUALIZAR_PREFERENCIAS_CONTACTO = "".concat(namespace, " actualizar pref contacto");
export var ACTUALIZAR_PREFERENCIAS_SUCCESS = "".concat(ACTUALIZAR_PREFERENCIAS_CONTACTO, ": success");
export var ACTUALIZAR_PREFERENCIAS_FAIL = "".concat(ACTUALIZAR_PREFERENCIAS_SUCCESS, ": fail");
export var EDITAR_PREFERENCIAS = "".concat(namespace, " editar pref");
export var VALIDAR_IDENTIDAD = "".concat(namespace, " validar identidad");
export var VALIDAR_IDENTIDAD_SUCCESS = "".concat(VALIDAR_IDENTIDAD, ": success");
export var VALIDAR_IDENTIDAD_FAIL = "".concat(VALIDAR_IDENTIDAD, ": fail");
export var SET_VALIDEZ = "".concat(namespace, " set validez");

/**
 * @param {Object} [params]
 * @param {boolean} [params.fullscreenProgress]
 * @param {boolean} [params.tieneContrasenaTemporal]
 * @return {{type: string, meta: {fullscreenProgress: boolean}}}
 */
export var getDetalle = function getDetalle() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    type: GET_DETALLE,
    meta: {
      fullscreenProgress: params.fullscreenProgress,
      tieneContrasenaTemporal: params.tieneContrasenaTemporal
    }
  };
};
export var getDetalleSuccess = function getDetalleSuccess(detalle, meta) {
  return {
    type: GET_DETALLE_SUCCESS,
    payload: detalle,
    meta: _objectSpread({}, meta)
  };
};
export var getDetalleFail = function getDetalleFail(cause, meta) {
  return {
    type: GET_DETALLE_FAIL,
    payload: cause,
    meta: _objectSpread({}, meta)
  };
};
export var setDetalle = function setDetalle(detalle) {
  return {
    type: SET_DETALLE,
    payload: detalle
  };
};
export var appendDetalle = function appendDetalle(detalle) {
  return {
    type: APPEND_DETALLE,
    payload: detalle
  };
};
export var actualizarDetalle = function actualizarDetalle(detalleUsuario) {
  return {
    type: ACTUALIZAR_DETALLE,
    payload: detalleUsuario
  };
};
export var actualizarDetalleSuccess = function actualizarDetalleSuccess() {
  return {
    type: ACTUALIZAR_DETALLE_SUCCESS
  };
};
export var actualizarDetalleFail = function actualizarDetalleFail(cause) {
  return {
    type: ACTUALIZAR_DETALLE_FAIL,
    payload: cause
  };
};
export var actualizarTelefono = function actualizarTelefono(payload) {
  return {
    type: ACTUALIZAR_TELEFONO,
    payload: payload
  };
};
export var actualizarTelefonoSuccess = function actualizarTelefonoSuccess() {
  return {
    type: ACTUALIZAR_TELEFONO_SUCCESS
  };
};
export var actualizarTelefonoFail = function actualizarTelefonoFail(cause) {
  return {
    type: ACTUALIZAR_TELEFONO_FAIL,
    payload: cause
  };
};
export var actualizarCorreo = function actualizarCorreo(payload) {
  return {
    type: ACTUALIZAR_CORREO,
    payload: payload
  };
};
export var actualizarCorreoSuccess = function actualizarCorreoSuccess() {
  return {
    type: ACTUALIZAR_CORREO_SUCCESS
  };
};
export var actualizarCorreoFail = function actualizarCorreoFail(cause) {
  return {
    type: ACTUALIZAR_CORREO_FAIL,
    payload: cause
  };
};
export var generarCodigoUsuario = function generarCodigoUsuario(type) {
  var numeroSecreto = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return {
    type: GENERAR_CODIGO_USUARIO,
    payload: {
      type: type,
      numeroSecreto: numeroSecreto
    }
  };
};
export var generarCodigoUsuarioSuccess = function generarCodigoUsuarioSuccess() {
  return {
    type: GENERAR_CODIGO_USUARIO_SUCCESS
  };
};
export var generarCodigoUsuarioFail = function generarCodigoUsuarioFail(cause) {
  return {
    type: GENERAR_CODIGO_USUARIO_FAILED,
    payload: cause
  };
};
export var actualizarPreferencias = function actualizarPreferencias(_ref) {
  var email = _ref.email,
    sms = _ref.sms,
    mail = _ref.mail,
    call = _ref.call,
    ecologicalReceipt = _ref.ecologicalReceipt;
  return {
    type: ACTUALIZAR_PREFERENCIAS_CONTACTO,
    payload: {
      email: email,
      sms: sms,
      mail: mail,
      call: call,
      ecologicalReceipt: ecologicalReceipt
    }
  };
};
export var actualizarPreferenciasSuccess = function actualizarPreferenciasSuccess(response, _ref2) {
  var mail = _ref2.mail,
    sms = _ref2.sms,
    call = _ref2.call,
    email = _ref2.email,
    ecologicalReceipt = _ref2.ecologicalReceipt;
  return {
    type: ACTUALIZAR_PREFERENCIAS_SUCCESS,
    payload: {
      mail: mail,
      sms: sms,
      call: call,
      email: email,
      ecologicalReceipt: ecologicalReceipt
    }
  };
};
export var actualizarPreferenciasFail = function actualizarPreferenciasFail(cause) {
  return {
    type: ACTUALIZAR_PREFERENCIAS_FAIL,
    payload: cause
  };
};
export var editarPreferencias = function editarPreferencias(_ref3) {
  var email = _ref3.email,
    sms = _ref3.sms,
    mail = _ref3.mail,
    call = _ref3.call,
    ecologicalReceipt = _ref3.ecologicalReceipt;
  return {
    type: EDITAR_PREFERENCIAS,
    payload: {
      email: email,
      sms: sms,
      mail: mail,
      call: call,
      ecologicalReceipt: ecologicalReceipt
    }
  };
};
export var validarIdentidad = function validarIdentidad(numDocSocio) {
  return {
    type: VALIDAR_IDENTIDAD,
    payload: numDocSocio
  };
};
export var validarIdentidadSuccess = function validarIdentidadSuccess(response) {
  return {
    type: VALIDAR_IDENTIDAD_SUCCESS,
    payload: response
  };
};
export var validarIdentidadFail = function validarIdentidadFail(cause) {
  return {
    type: VALIDAR_IDENTIDAD_FAIL,
    payload: cause
  };
};
export var setValidez = function setValidez(value) {
  return {
    type: SET_VALIDEZ,
    payload: value
  };
};