import history from '@cencosud/puntos-core/src/features/router/history';
export var handleClickTarget = function handleClickTarget(target, eILink) {
  var sameHost = window.location.hostname;
  if (target === 'Misma ventana') {
    if ((eILink.startsWith('http://') || eILink.startsWith('https://')) && (!eILink.includes(sameHost) || eILink.includes(sameHost) && !eILink.includes('/puntos/'))) {
      window.open(eILink, '_self');
    } else {
      var p = 0;
      if (eILink.includes('/puntos/')) {
        p = 7;
      }
      if (eILink.startsWith('/')) {
        var url = eILink.substr(p);
        history.push(url);
      }
      if (eILink.includes(sameHost)) {
        var index = sameHost.length + (eILink.startsWith('https://') ? 8 : 7) + p;
        var _url = eILink.substr(index);
        history.push(_url);
      }
    }
  } else {
    window.open(eILink, '_blank');
  }
};